@import './mixins';

.container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: var(--lightPink);
  padding: 0 0 0 5vw;
  position: relative;
  max-height: 900px;
  box-sizing: border-box;
  z-index: -1;
  .logo {
    position: relative;
    z-index: 2;
    img {
      position: relative;
      fill: green !important;
      width: 50vw;
      display: block;
      z-index: 2;
    }

    img:nth-child(2) {
      width: 38vw;
      margin: 40px 0 10vh 25vw;
    }
  }

  .landingImg {
    position: absolute;
    right: 11vw;
    top: 22vh;
    width: 45vw;
    height: 45vw;
    z-index: 1;
    img {
      object-fit: cover;
      max-width: 100%;
      max-height: 100%;
    }
  }
  
  @include sm {
    height:45vh;
    .landingImg {
      right: 5vw;
      top: 11vh;
      width: 59vw;
    }
    .logo {
      img {
        width: 60vw;
      }
      img:nth-child(2) {
        width: 45vw;
        margin: 10px 0 10vh 25vw;
      }
    }
  }
}
