@import './mixins';

.dualCol {
    width: 100vw;
    justify-content: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;

    .column,
    .rowCol {
        padding: 25px 5px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        width: 43vw;
        background-color: var(--offWhite);

        h2 {
            font-family: 'calendas';
            font-weight: 100;
            align-self: flex-start;
        }

        h3 {
            font-family: 'calendas-italic';
            margin: 0;
            text-align: center;
            font-weight: 100;
        }

        p {
            padding: 0 55px;

            @include sm {
                padding: 0 10px;
                text-align: center;
                margin: 0;
            }
        }


    }


    .headerOverImage {
        justify-content: center;
        align-items: center;

        img {
            width: 10%;
            height: auto;
        }
    }

    &.rowLayout {
        flex-direction: column-reverse;
        justify-content: center;
        align-content: center;


        .row {
            display: flex;


            @include sm {
                flex-direction: column;

            }

            .rowCol {
                width: 43vw;
                justify-content: flex-end;
                align-items: center;

                h3 {
                    position: relative;
                    top: -50px;

                    @include sm {
                        top: 0;
                    }
                }

                @include sm {
                    width: 89vw;
                }
            }
        }

        .column {
            width: 86vw;
            display: flex;
            justify-content: center;
            align-content: center;
            align-items: center;

            img {
                height: initial;
            }
        }


    }

    &.headerCenter {
        .column {
            h3 {
                align-self: center;
            }

        }

    }


    &.ltr {

        flex-direction: row-reverse;
    }

    ul {
        margin: 0 0 0 0;
        padding: 0;
        list-style: none;

        li {
            font-size: 18px;
            line-height: 30px;
            margin: 0 100px 30px 0;

            h4 {

                font-size: 28px;
                line-height: 30px;
                padding: 0;
                margin: 0 0 3px 0;
                font-weight: 500;
            }
        }
    }

    img {
        min-width: 85%;
        max-width: 95%;
        max-height: 80vh;
        height: 75vh;
        object-fit: cover;

        @include sm {
            height: 30vh;
        }

    }

    @include sm {
        flex-direction: column;
        align-items: center;

        .column {
            width: 90vw;
            align-items: center;

            h3 {
                font-size: 36px;
                line-height: 38px;
                margin: 10px 0;
            }

            p {
                padding: 0;
                text-align: center;
                font-size: 16px;
                line-height: 22px;
            }

            h2 {
                font-size: 36px;
                margin-top: 0;
            }

            ul {
                h4 {
                    font-size: 20px;
                    line-height: 22px;
                }

                li {
                    font-size: 16px;
                    line-height: 22px;
                    margin: 0 0 30px 0;

                }
            }
        }
    }
}