// Small tablets and large smartphones (landscape view)
$screen-sm-max: 565px;

// Small tablets (portrait view)
$screen-md-max: 1100px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1700px;

// Small devices
@mixin sm {
  @media (max-width: #{$screen-sm-max}) {
      @content;
  }
}

// landscape
@mixin sm-ln {
  @media only screen 
  and (min-device-width: 319px) 
  and (max-device-width: 813px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) { 
    @content;
  }
}

// Medium devices
@mixin md {
  @media (max-width: #{$screen-md-max}) {
      @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
      @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
      @content;
  }
}