@import './mixins.scss';

.cover {

    height:100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    color:white;
    line-height: 60px;
    font-family: 'calendas-italic';
    position: relative;
    padding:0 0;

    .content {
        position: absolute;
        top:80px;
        right:0;
    }
    a {
        display: flex;
        justify-content: flex-start;
        align-content: center;
        height:30px;
        padding:0 100px;
        text-align: left;
        font-size: 21px;
        line-height: 30px;
        width:450px;
        color:var(--blue);
        text-decoration: none;
        font-family: 'calendas';
        margin: 0 0 10px 0;
        
        svg {
            max-height: 100%;
            fill: var(--blue);
        }
    }

    h2 {
        padding:0 120px;
        margin:0 0 10px 0;
        line-height: 60px;
        font-weight: 400;
    }

    img {
        position: absolute;
        top:0;
        min-width: 100%;
        max-width: 100%;
        height: 100%;
        z-index: -1;
        object-fit: cover;
    }

    @include sm {
        flex-direction: row;
        justify-content: flex-end;
        height:40vh;
        
        .content {
            padding:20px 20px 0 0;
            position: initial;
        }
        h2 {
            margin-left:20px;
            line-height: 20px;
            font-size: 20px;
            padding:0;
        }

        a {
            height:20px;
            font-size: 14px;
            line-height: 20px;
            padding:0;
            width:initial;
        }

        img {
            object-position: right;
        }
    }
}