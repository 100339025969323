@import './mixins';

.pip {
    height:50px;
    width:50px;
    background-color: #FFCE00;
    position:fixed;
    z-index: 100;
    top:30px;
    left:30px;
    border-radius: 50%;
    display: block;
    cursor:pointer;
    transition: all 500ms ease-in-out;
 
    &.active {
 
        background-color: var(--yellow);
       transform:scale(80);
      
    }
}

.menu {
    background-color: var(--yellow);
    width:100vw;
    height:100vh;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top:0;
    z-index: 200;
    pointer-events: none;
    .cross {
        font-size: 36px;
        color:var(--offWhite);
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        position: absolute;
        top:50px;
        left:50px;
        cursor: pointer;
    }
    ul {
        list-style: none;
        margin:0;
        padding:0;
        li {
            font-size: 65px;
            margin:65px 0;
            text-align: center;
            a {
                font-family: 'calendas-italic';
                font-size: 55px;
                text-align: center;
                font-weight: 100;

                color:var(--offWhite);
                text-decoration: none;
            }
        }
    }

    @include sm {
        ul {
            li {
            margin:70px 0;
            }
        }
    }

    &.active {
        pointer-events: initial;
    }
}