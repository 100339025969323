@import './mixins';

.blockQuote {
    width: 100vw;
    min-height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color:white;
    line-height: 60px;
    font-family: 'calendas-italic';
    align-items: center;
    text-align: center;
    padding:40px 0;
    h2 {
        margin-bottom:0;
        font-weight: 100;
    }
    p {
        color:var(--offWhite);
        font-size: 42px;
        line-height: 52px;
        padding:0 350px;
    }

    @include md {
        p {
            padding: 0 50px;
        }
    }

    @include sm {
        min-height: 20vh;
        h2 {
            font-size: 26px;
            margin:0;
        }
        p {
            margin:0;
            font-size: 23px;
            line-height: 26px;
            padding:0 20px;
        }
    }
}