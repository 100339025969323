:root {
  --offWhite: #f9efe8;
  --lightPink: rgba(249, 238, 233, 1);
  --yellow: #fcb021;
  --pink: #ecbec5;
  --red: #e35036;
  --green: #5f705c;
  --blue: #0d1c2c;
}

@font-face {
  font-family: "calendas-italic";
  src: url("./styles/Calendas_Plus_Italic.otf") format("opentype");
}

@font-face {
  font-family: "calendas";
  src: url("./styles/Calendas_Plus.otf") format("opentype");
}

@font-face {
  font-family: grotesk;
  src: url("./styles/SuperGroteskC-Rg.otf") format("opentype");
}

body {
  font-family: "calendas";
}

h2 {
  font-size: 48px;
}
h3{
  font-size: 42px;
}
p {
  font-size:18px;
  line-height: 25px;
}
